import $ from "jquery";
import Cookies from "js-cookie";
import { validateEmail, validateEmailRegex } from "../../helpers/utils";
import winCollector from "../../helpers/win-collector";
import "../../helpers/utils";

function hideNewsletterPopup(speed) {
    setTimeout(() => {
        $("#popup-newsletter-collector").animate(
            {
                bottom: "-1000px",
            },
            speed,
        );
    }, 10);
}

function addToNewsletterPopup(e) {
    const $popupNewsletterInput = $("#popup_newsletterinput");
    if (validateEmailRegex($popupNewsletterInput.val())) {
        $.post("/add_to_newsletter", {
            _token: window.CSRF_TOKEN,
            email: $popupNewsletterInput.val(),
            popup: 1,
        });
        $(".popup_newsletterinput").attr("disabled", true);
        $(".newsletter_wrap").html(
            `<button class="btn btn-sm btn-success" disabled style="margin:auto;">${window.TEXT46}</button>`,
        );
        Cookies.set("lp_newsletter_popshown", 4, { expires: 90, path: "/" });
        setTimeout(() => {
            hideNewsletterPopup(3000);
        }, 3000);
    } else {
        e.preventDefault();
        $popupNewsletterInput.val("");
        $popupNewsletterInput.attr("placeholder", window.TEXT47);
        setTimeout(() => {
            // $("#popup_newsletterinput").val('')
            $popupNewsletterInput.attr("placeholder", window.TEXT44);
        }, 1000);
    }
}

$(() => {
    if (Cookies.get("lp-ftvs") !== undefined) winCollector();

    $("#popup_newsletter_signup").on("click", (e) => {
        addToNewsletterPopup(e);
    });
    $("#popup-newsletter-collector .modal-close").on("click", (e) => {
        Cookies.set("lp_newsletter_popshown", 4, { expires: 14, path: "/" });
        hideNewsletterPopup(3000);
    });

    $("#newsletter_signup").click((e) => {
        if (validateEmail($("#newsletterinput").val())) {
            $.post("/add_to_newsletter", {
                _token: window.CSRF_TOKEN,
                email: $("#newsletterinput").val(),
            });
            $("#newsletter_wrap").html(
                `<button class="btn btn-purple-dark" disabled style="margin:auto;">${window.TEXT46}</button>`,
            );

            if (document.getElementById("lux-modal-newsletter")) {
                setTimeout(() => {
                    document
                        .getElementById("lux-modal-newsletter")
                        .classList.remove("active");
                }, 1500);
            }
        } else {
            e.preventDefault();
            $("#newsletterinput").val(window.TEXT47);
            setTimeout(() => {
                $("#newsletterinput").val("");
            }, 1000);
        }
    });
});
